@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 768px) {
  body {
    cursor: none !important;
  }

  .cursor-pointer {
    cursor: none !important;
  }
}

.custom-cursor {
  position: fixed;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  background-color: rgba(var(--colors-textPrimary), var(--tw-text-opacity));
  border-radius: 50%;
  pointer-events: none;
  z-index: 9999;
  transform: translate(-50%, -50%);
}

.trailing-cursor {
  position: fixed;
  top: 0;
  left: 0;
  width: 35px;
  height: 35px;
  background-color: rgba(var(--colors-textPrimary), var(--tw-text-opacity));
  border-radius: 50%;
  pointer-events: none;
  z-index: 9998;
  opacity: 0.5;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-out;
}

.cursor-grow {
  width: 45px;
  height: 45px;
  border: 2px solid rgba(var(--colors-textPrimary), var(--tw-text-opacity));
  background-color: transparent;
  transition: width 0.2s ease, height 0.2s ease;
}
